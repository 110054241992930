import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useRegistroLista() {
  const propcompListTable = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'City', value: 'city' },

    // { text: 'Market', value: 'market' },
    { text: 'Address', value: 'address' },
    { text: 'State', value: 'state' },

    // { text: 'ZIP Code', value: 'zip' },
    { text: 'Price', value: 'listPrice' },
    { text: 'Type', value: 'compPropertyType' },
    { text: 'Status', value: 'comparisonStatus' },
    { text: 'Beds', value: 'propertyBeds' },
    { text: 'Baths', value: 'propertyBaths' },
    { text: '', value: 'controls' },
  ]

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: 15,
  })
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])
  const isScanner = ref(false)

  const fetchPropsComparison = () => {
    store
      .dispatch('app-invoice/fetchPropsComparison', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, total } = response
        const filteredData = data
        propcompListTable.value = filteredData
        totalInvoiceListTable.value = total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    fetchPropsComparison()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    propcompListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    isScanner,
    fetchPropsComparison,
  }
}
