<template>
  <div>
    <v-toolbar
      class="primary"
      short
    >
      <v-app-bar-nav-icon style="color:white;"></v-app-bar-nav-icon>

      <v-toolbar-title style="color:white;">
        Property Comparison
      </v-toolbar-title>
      <v-spacer />
      <span style="color:white;">Market:&nbsp;</span>
      <v-select
        v-model="marketSelected"
        :items="marketList"
        item-text="marketShortName"
        item-value="marketShortName"
        placeholder="Select Market"
        solo
        dense
        style="width:180px; height: 42px;"
        class="shrink"
        @change="onMarketChange"
      ></v-select>
      <span style="color:white;">&nbsp;&nbsp;Comparison Status:&nbsp;</span>
      <v-select
        v-model="statusSelected"
        :items="compStatus"
        item-text="value"
        item-value="value"
        placeholder="Select Status"
        solo
        dense
        style="width:180px; height: 42px;"
        class="shrink"
        @change="onStatusChange"
      ></v-select>
      <span style="color:white;">&nbsp;&nbsp;</span>
      <v-btn
        color="secondary"
        class="me-3"
        small
        :to="{ name: 'apps-propertycomp-add' }"
      >
        <v-icon
          size="18"
          class="me-1"
        >
          {{ icons.mdiPlus }}
        </v-icon>
        <span>Create Comparison</span>
      </v-btn>
    </v-toolbar>

    <v-card id="modulo-list">
      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="propcompListTable"
        :options.sync="options"
        :server-items-length="totalInvoiceListTable"
        :loading="loading"
        class="text-no-wrap"
        no-data-text="No records"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text':'Items per page'
        }"
      >
        <!-- id -->
        <template #[`item.id`]="{item}">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-propertycomp-edit', params: { id: item.idRegistro } }"
          >
            {{ item.idRegistro }}
          </router-link>
        </template>
        <template #[`item.listPrice`]="{item}">
          <span>{{ item.listPrice.toLocaleString("en-US") }}</span>
        </template>
        <template #[`item.fechaRegistro`]="{ item }">
          <span>{{ new Date(item.fechaRegistro).toLocaleDateString() }}</span>
        </template>
        <template v-slot:[`item.controls`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-propertycomp-edit', params: { id: item.id } }"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  x-small
                  v-on="on"
                >
                  <v-icon
                    size="20"
                    color="error"
                  >
                    {{ icons.mdiEyeOutline }}
                  </v-icon>
                </v-btn>
              </template>
              <span>View Details</span>
            </v-tooltip>
          </router-link>
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'apps-propertycomp-adjustment', params: { id: item.id } }"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  fab
                  x-small
                  v-on="on"
                >
                  <v-icon
                    size="20"
                    color="success"
                  >
                    {{ icons.mdiTrendingUp }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Adjustments Comparison</span>
            </v-tooltip>
          </router-link>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                fab
                x-small
                @click="onDeleteComparisonClick(item.id)"
                v-on="on"
              >
                <v-icon
                  size="20"
                  color="primary"
                >
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete Comparison</span>
          </v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} of {{ items.itemsLength }}
        </template>
      </v-data-table>
      <!-- <PropertyAdjustment
        v-model="showPropertyAdjustments"
        :data="propertyDataLocal"
      /> -->
    </v-card>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDelete,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiMinus,
} from '@mdi/js'

import { onUnmounted, ref } from '@vue/composition-api'

// store module
import store from '@/store'
import router from '@/router'

// store module
import EventBus from '@/views/event-bus'
import propcompStore from './propcompStore'

// composition function
import usePropLista from './usePropLista'

// import PropertyAdjustment from './adjustments/PropertyAdjustments.vue'

export default {
  // components: {
  //   PropertyAdjustment,
  // },
  data() {
    return {
      showPropertyAdjustments: false,
      propertyDataLocal: [],
    }
  },
  methods: {
    async onViewAdjustmentsClick(item) {
      this.showPropertyAdjustments = true
      EventBus.$emit('refresh_adjustment', item.id)
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, propcompStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      propcompListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalInvoiceListTable,
      loading,
      selectedTableData,
    } = usePropLista()

    const selectedAction = ref('')
    const marketList = ref([])
    const marketSelected = ref('')
    store.dispatch('app-invoice/fetchMarkets').then(response => {
      marketList.value = response.data
      const equipo = JSON.parse(localStorage.getItem('marketSelected'))
      marketSelected.value = equipo.value
    })

    const onMarketChange = () => {
      // Set user's ability in localStorage for Access Control
      localStorage.setItem('marketSelected', JSON.stringify(marketSelected))
    }

    const statusSelected = ref('')
    const status = JSON.parse(localStorage.getItem('statusCompSelected'))
    if (status != null) {
      statusSelected.value = status.value
    }

    const onStatusChange = () => {
      // Set user's ability in localStorage for Access Control
      localStorage.setItem('statusCompSelected', JSON.stringify(statusSelected))
    }

    const onDeleteComparisonClick = item => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This action will delete this  comparison record.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete record!',
      }).then(result => {
        console.log(item)
        if (result.isConfirmed) {
          store.dispatch('app-invoice/fetchDeleteComparison', { id: item }).then(() => {
            Swal.fire({
              text: 'Comparison deleted successfully!',
              toast: true,
              position: 'top-right',
              iconColor: 'white',
              icon: 'success',
              customClass: {
                popup: 'colored-toast',
              },
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })

            router.go(0)
          })

          Swal.fire(
            'Deleted!',
            'The record is deleted.',
            'success',
          )
        }
      })
    }

    return {
      selectedTableData,
      totalInvoiceListTable,
      propcompListTable,
      selectedAction,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      loading,
      marketList,
      marketSelected,
      onMarketChange,
      statusSelected,
      onStatusChange,
      onDeleteComparisonClick,
      compStatus: [
        { value: 'Active' },
        { value: 'Cancelled' },
        { value: 'Closed' },
        { value: 'Analysis' },
        { value: 'Report' },
      ],

      // icons
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDelete,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiMinus,
      },
    }
  },
}
</script>

<style lang="scss">

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
    font-size: 12px;
    padding: 0 16px;
}

</style>
