var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"primary",attrs:{"short":""}},[_c('v-app-bar-nav-icon',{staticStyle:{"color":"white"}}),_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v(" Property Comparison ")]),_c('v-spacer'),_c('span',{staticStyle:{"color":"white"}},[_vm._v("Market: ")]),_c('v-select',{staticClass:"shrink",staticStyle:{"width":"180px","height":"42px"},attrs:{"items":_vm.marketList,"item-text":"marketShortName","item-value":"marketShortName","placeholder":"Select Market","solo":"","dense":""},on:{"change":_vm.onMarketChange},model:{value:(_vm.marketSelected),callback:function ($$v) {_vm.marketSelected=$$v},expression:"marketSelected"}}),_c('span',{staticStyle:{"color":"white"}},[_vm._v("  Comparison Status: ")]),_c('v-select',{staticClass:"shrink",staticStyle:{"width":"180px","height":"42px"},attrs:{"items":_vm.compStatus,"item-text":"value","item-value":"value","placeholder":"Select Status","solo":"","dense":""},on:{"change":_vm.onStatusChange},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}}),_c('span',{staticStyle:{"color":"white"}},[_vm._v("  ")]),_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary","small":"","to":{ name: 'apps-propertycomp-add' }}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Create Comparison")])],1)],1),_c('v-card',{attrs:{"id":"modulo-list"}},[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.propcompListTable,"options":_vm.options,"server-items-length":_vm.totalInvoiceListTable,"loading":_vm.loading,"no-data-text":"No records","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text':'Items per page'
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'apps-propertycomp-edit', params: { id: item.idRegistro } }}},[_vm._v(" "+_vm._s(item.idRegistro)+" ")])]}},{key:"item.listPrice",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.listPrice.toLocaleString("en-US")))])]}},{key:"item.fechaRegistro",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.fechaRegistro).toLocaleDateString()))])]}},{key:"item.controls",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'apps-propertycomp-edit', params: { id: item.id } }}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":""}},on),[_c('v-icon',{attrs:{"size":"20","color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Details")])])],1),_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'apps-propertycomp-adjustment', params: { id: item.id } }}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":""}},on),[_c('v-icon',{attrs:{"size":"20","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Adjustments Comparison")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.onDeleteComparisonClick(item.id)}}},on),[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Comparison")])])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" of "+_vm._s(items.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }